import { createClient } from 'graphql-ws';
import * as merchant from './merchant'
import * as transactions from './transactions'
import * as settlements from './settlements'
import * as chargebacks from './chargebacks'
import * as subscribe from './subscribe'
import * as invoices from './invoices'
import { API } from "aws-amplify";

const handleResponse = (response, onError = false) => {
    if (response.status !== 200) {
        return response
            .json()
            .then(error => {
                return Promise.reject(onError ? onError : error)
            })
            .catch(err => {
                if (response.status === 401 || response.status === 403) {
                    window.location = '/unauthorized'
                    return Promise.reject(
                        `unauthorized access attempt:${response.status}`
                    )
                }
                else if (response.status === 401 || response.status === 404) {
                    return Promise.reject(
                        `Not Found:${response.status}`
                    )
                }
                else {
                    return Promise.reject(onError ? onError : err)
                }
            })
    }
    else {
        try {
            return Promise.resolve(response.json())
        }
        catch (error) {
            return Promise.reject(error)
        }
    }
}

const formatToken = (token) => {
    const strigifiedToken = JSON.stringify(token)
    const encodedToken = btoa(strigifiedToken)
    return encodeURI(encodedToken)
}

const createSocket = (accessToken, idToken, connect) => {
    let token = {
        jwtAccessToken: accessToken,
        jwtIdToken: idToken,
        userPoolId: process.env.REACT_APP_USER_POOL
    }
    const formattedToken = formatToken(token)
    return createClient({
        url: `${process.env.REACT_APP_API_ENDPOINT}${process.env.REACT_APP_PARTNER}/?security_tokens=${formattedToken}`,
        connectionAckWaitTimeout: Infinity,
        lazy: false,
        onNonLazyError: (errorOrCloseEvent) => {
            console.log(errorOrCloseEvent)
        },
        on: {
            connected: (socket) => {
                connect(socket)
            },
            pong: (payload) => {
                console.log('pong',payload)
            },
            error: (err) => {
                console.error(err);
            },
        }
      });
  }

const socketQuery = async(websocket, query) => {
    return await new Promise((resolve, reject) => {
        let result;
        websocket.subscribe(
          {
            query: query,
          },
          {
            next: (data) => result = data,
            error: reject,
            complete: () => resolve(result),
          },
        )
      })     
}

const socketSubsciption = async(websocket, action, query) => {
    return await new Promise((resolve, reject) => {
        websocket.subscribe(
          {
            query: query
          },
          {
            next: (data) => action(data),
            error: reject,
            complete: () => resolve(true)
          }
        )
      })     
}

const appSyncQuery = (query, variables = {}) => {
    return API.graphql({query, variables})
}


export {
    appSyncQuery,
    createSocket,
    socketQuery,
    socketSubsciption,
    handleResponse,
    merchant,
    transactions,
    settlements,
    chargebacks,
    invoices,
    subscribe
}
