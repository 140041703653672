import { useState, useEffect, useMemo } from 'react';

import {
  useLocation
} from "react-router-dom";

import * as ROUTES from "./constants/routes";
import * as general from './constants/general';

const generateMenu = (badge) => () =>{
    return [{
          tag: "payments",
          icon: "usd-square",
          label: "Payments Home",
          isCategory: true,
          to: ROUTES.PAYMENTS,
          subContent: [
            {
              to: ROUTES.ALL_TRANSACTIONS,
              tag: 'all-transactions',
              label: 'All Transactions',
            },
          ]
        },
        {
            to: ROUTES.INVOICES,
            tag: 'invoices',
            icon: 'file-invoice-dollar',
            label: 'Invoices',
            isCategory: false
        },
        {
            to: ROUTES.SETTLEMENTS,
            tag: 'settlements',
            icon: 'exchange-alt',
            label: 'Settlements',
            isCategory: false
        },
        {
            to: ROUTES.DISPUTES,
            tag: "disputes",
            icon: "backward",
            label: "Disputes",
            isCategory: false,
            badgeNumber: badge
        },
        {
            to: ROUTES.MERCHANT_SETTINGS,
            tag: "settings",
            icon: "users-cog",
            label: "Settings",
            isCategory: false
        }
    ];
};

const prepRoute = (route, replacements = []) => {
    return replacements.reduce((prepped, item) => {
        const keyed = `:${item.key}`
        return prepped.replace(keyed, item.value)
    }, route)
}

export {
    generateMenu,
    prepRoute
};

export const statusChip = {
    settled: {
      color: "grey",
      textColor: "white",
      text: "Settled"
    },
    paid: {
        color: "grey",
        textColor: "white",
        text: "Paid"
    },
    active: {
        color: "mint",
        textColor: "black",
        text: "Active"
    },
    overdue: {
        color: "raspberry",
        textColor: "white",
        text: "Overdue"
    },
    reversed: {
      color: "yellow",
      textColor: "black",
      text: "Refunded"
    },
    refunded: {
      color: "yellow",
      textColor: "black",
      text: "Refunded"
    },
    pending: {
      color: "grey-2",
      textColor: "black",
      text: "Pending"
    },
    succeeded: {
      color: "mint",
      textColor: "black",
      text: "Succeeded"
    },
    failed: {
      color: "raspberry",
      textColor: "white",
      text: "Failed"
    },
    partially_refunded: {
      color: "yellow",
      textColor: "black",
      text: "Partially Refunded"
    },
    partially_paid: {
        color: "yellow",
        textColor: "black",
        text: "Partially Paid"
    }
  };

export const chargebackChip = {
    won: {
      color: "mint",
      textColor: "black",
      text: "Won"
    },
    lost: {
      color: "raspberry",
      textColor: "white",
      text: "Lost"
    },
    pending: {
      color: "grey-2",
      textColor: "black",
      text: "Pending"
    },
    inquiry: {
      color: "yellow",
      textColor: "black",
      text: "Action Required"
    }
  };
  

export const formatFee = (value) => {
  const newValue = value.toString().replace(/[^0-9-]/g, '');
  let amount = parseFloat(newValue) ?? 0;
  return amount ? ((amount / 100).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '0.00';
}

export const brandClasses = {
    VISA: "pay-theory-card-visa",
    DISCOVER: "pay-theory-card-discover",
    MASTERCARD: "pay-theory-card-mastercard",
    AMERICAN_EXPRESS: "pay-theory-card-american-express",
    AMEX: "pay-theory-card-american-express",
    CASH: "pay-theory-cash-badge",
    ACH: "pay-theory-ach-badge"
  };

export const formatBasisPoints = (bp) => {
    const originalAmount = 100000;
    const totalAmount = Math.round(originalAmount / (1 - bp / 10000));
    const fee = totalAmount - originalAmount;
    return ((fee / originalAmount) * 100).toFixed(2);
  };

export const formatDateAbrevMonth = (date) => {
    const dated = new Date(date);
    const month = dated.getMonth();
    const day = dated.getDate();
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    return `${months[month]} ${day}`;
}

  export const formatDate = (date) => {
      const dated = new Date(date);
      const month = dated.getMonth();
      const day = dated.getDate();
      const year = dated.getFullYear();
      const currentYear = new Date().getFullYear();

      const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
      return `${months[month]} ${day}${currentYear !== year ? `, ${year}` : ''}`;
  };
  
  export const formatFullDate = (stamp) => {
    const dated = new Date(stamp);
    const month = (dated.getMonth() + 1).toString().padStart(2, '0');
    const day = (dated.getDate()).toString().padStart(2, "0");
    const year = dated.getFullYear();
    const hour = (dated.getHours() % 12 || 12).toString().padStart(2, "0");
    const minute = (dated.getMinutes()).toString().padStart(2, "0");
    const amOrPm = dated.getHours() > 11 ? "PM" : "AM";
    return `${month}/${day}/${year} ${hour}:${minute} ${amOrPm}`;
  };

const mapValue = (value, next) => {
    if (value.toLowerCase().includes("date")) {
        return formatFullDate(next[`${value}`]);
    } else if (value.toLowerCase().includes("amount") || value.toLowerCase().includes("fee") || value.toLowerCase().includes("adjustment")) {
        return `"${convertAmount(next[`${value}`], next.currency)}"`;
    } else {
        if(typeof value ==="string" && value.includes(',')) value = `"${value}"`;
        return next[`${value}`];
    }
};

  const camelToSnake = (str) => {
    return str.replace(/([A-Z])/g, (g) => `_${g[0].toLowerCase()}`);
  };
  
  function arrayToCSV(objArray) {
    if (objArray.length > 0) {
      const array =
        typeof objArray !== "object" ? JSON.parse(objArray) : objArray;
      const str =
        `${Object.keys(array[0])
          .map((value) => `"${camelToSnake(value)}"`)
          .join(",")}` + "\r\n";
  
      return array.reduce((str, next) => {
        str +=
          `${Object.keys(next)
            .map((value) => mapValue(value, next))
            .join(",")}` + "\r\n";
        return str;
      }, str);
    }
  }
  
  /* global Blob URL */
  export const downloadCSV = (items, fileName) => {
    var link = document.createElement("a");
  
    // Avoid scrolling to bottom
    link.style.top = "0";
    link.style.left = "0";
    link.style.position = "fixed";
  
    document.body.appendChild(link);
    const text = arrayToCSV(items)
    const data = new Blob([text], { type: "text/csv" });
    const url = URL.createObjectURL(data);
    link.href = url; 
    link.download = `${fileName}.csv`;
    link.onclick = (e) => {
      if (items.length === 0) e.preventDefault();
    };
    link.click();
  
    document.body.removeChild(link);
  };

export const convertArrayToGraphQLString = (array) => {
    return array.reduce((str, item, index) => {
      if(index === array.length - 1) {
        return str + `"${item}"]`; }
      else {
        return str + `"${item}",`;
      }
    }, "[");
  }

export function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

export const findPaymentMethodLogo = (item) => {
  item.cardBrand = item.cardBrand === 'AMEX' ? 'AMERICAN_EXPRESS' : item.cardBrand;
  return item.cardBrand ? item.cardBrand.toUpperCase() : item.paymentType
}

export const compareState = (a, b) => {
  if (typeof a === 'object' && typeof b === 'object') {
    let result = false;
    Object.keys(a).forEach(key => {
      if (a[key] !== b[key]) {
        result = true;
      }
    });
    return result;
  } else {
    return a !== b;
  }
}

export const useDebounce = (value, action, delay) => {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(
    () => {
      // Set debouncedValue to value (passed in) after the specified delay
      const handler = setTimeout(() => {
        if(compareState(value, debouncedValue)){
          setDebouncedValue(value);
          action(value)
        }
      }, delay);

      // Return a cleanup function that will be called every time ...
      // ... useEffect is re-called. useEffect will only be re-called ...
      // ... if value changes (see the inputs array below). 
      // This is how we prevent debouncedValue from changing if value is ...
      // ... changed within the delay period. Timeout gets cleared and restarted.
      // To put it in context, if the user is typing within our app's ...
      // ... search box, we don't want the debouncedValue to update until ...
      // ... they've stopped typing for more than 500ms.
      return () => {
        clearTimeout(handler);
      };
    },
    // Only re-call effect if value changes
    // You could also add the "delay" var to inputs array if you ...
    // ... need to be able to change that dynamically.
    [value] 
  );

  return debouncedValue;
}

export const validDate = date => {
  return date.match(/^(0[1-9]|1[0-2]|[1-9])\/(0[1-9]|[12][0-9]|3[01]|[1-9])\/[0-9]{4}$/) ? true : false;
}

export const validEmail = email => {
    return email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/) ? true : false;
}


export const formatAWSDate = (date) => {
    const dateArray = date.split('-');
    return `${dateArray[1]}/${dateArray[2]}/${dateArray[0]}`;
}

export const convertToAwsDate = (date) => {
    const dateArray = date.split('/');
    return `${dateArray[2]}-${dateArray[0]}-${dateArray[1]}`;
}

const addMonths = (date, months) => {
  var d = date.getDate();
  date.setMonth(date.getMonth() + +months);
  if (date.getDate() !== d) {
    date.setDate(0);
  }
  return date;
}

const addDays = (date, days) => {
  date.setDate(date.getDate() + +days);
  return date;
}

const addYears = (date, years) => {
  var d = date.getDate();
  date.setFullYear(date.getFullYear() + +years);
  if (date.getDate() !== d) {
    date.setDate(0);
  }
  return date;
}

let dateOffsetValues = {
  '1D': {
    function: addDays,
    value: 1
  }, 
  '1W': {
    function: addDays,
    value: 7
  },
  '1M': {
    function: addMonths,
    value: 1
  },
  '3M': {
    function: addMonths,
    value: 3
  },
  '1Y': {
    function: addYears,
    value: 1
  }
}

export const formatDateToISO = (value, endOfDay) => {
  let date = new Date(value).toISOString()
  let dateString = date.split('T')[0]
  let ending = endOfDay ? 'T23:59:59.999Z' : 'T00:00:00.000Z';
  return dateString + ending;
}


export const findDateOffset = (value, endOfDay) => {
  let valueObject = dateOffsetValues[value];
  let date = valueObject.function(new Date(), -valueObject.value);
  let dateString = date.toISOString().split('T')[0];
  let ending = endOfDay ? 'T23:59:59.999Z' : 'T00:00:00.000Z';
  return dateString + ending;
}

export const capitalize = (string) => {
  string = string.toLowerCase();
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const convertAmount = (amount, currency = "USD") => {
    const nf = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: currency,
        minimumFractionDigits: 2
    });
    return nf.format(amount / 100)
}

export const normalizeTransaction = transaction => {
    return {
        merchantUid: transaction.merchant_uid,
        transactionId: transaction.transaction_id,
        transactionDate: transaction.transaction_date,
        status: transaction.status,
        settlementBatch: transaction.settlement_batch,
        paymentType: transaction.payment_method?.payment_type?.toUpperCase(),
        cardBrand: transaction.payment_method?.card_brand?.toUpperCase(),
        lastFour: transaction.payment_method?.last_four,
        fullName: transaction.payment_method?.payor?.full_name,
        reference: transaction.reference,
        phone: transaction.payment_method?.payor?.phone,
        email: transaction.payment_method?.payor?.email,
        accountCode: transaction.account_code,
        transactionType: transaction.transaction_type,
        disputeStatus: transaction.dispute_status,
        netAmount: transaction.net_amount,
        grossAmount: transaction.gross_amount,
        refundedAmount: transaction.refunded_amount,
        fees: transaction.fees,
        currency: transaction.currency,
        failureReasons: transaction.failure_reasons,
        refundReason: transaction.refund_reason,
    }
}

export const normalizeSettlement = settlement => {
    return {
        merchantUid: settlement.merchant_uid,
        settlementBatch: settlement.settlement_batch,
        settlementDate: settlement.settlement_date,
        status: settlement.status,
        transactionDebitCount: settlement.transaction_debit_count,
        transactionReversalCount: settlement.transaction_reversal_count,
        transactionDisputeCount: settlement.transaction_dispute_count,
        netAmount: settlement.net_amount,
        grossAmount: settlement.gross_amount,
        totalFees: settlement.total_fees,
        totalAdjustments: settlement.total_adjustments,
        currency: settlement.currency
    }
}

export const normalizeInvoice = invoice => {
    return {
        merchantUid: invoice.merchant_uid,
        invoiceId: invoice.invoice_id,
        status: invoice.status,
        createdDate: invoice.created_date,
        dueBy: invoice.due_by,
        feeMode: invoice.fee_mode,
        invoiceAmount: invoice.invoice_amount,
        invoiceDescription: invoice.invoice_description,
        invoiceDate: invoice.invoice_date,
        invoiceName: invoice.invoice_name,
        isSecure: invoice.is_secure,
        merchantInvoiceNumber: invoice.merchant_invoice_number,
        offlineTransactions: invoice.offline_transactions,
        payorName: invoice.payor?.full_name,
        email: invoice.payor?.email,
        phone: invoice.payor?.phone,
        payorId: invoice.payor?.payor_id,
        securityPin: invoice.security_pin,
        settings: invoice.settings ? JSON.parse(invoice.settings) : {},
        totalPaidAmount: invoice.total_paid_amount,
    }
}

export const isPastDue = (date) => {
    const today = new Date();
    const dueDate = new Date(date);
    return dueDate < today;
}

export const findInvoiceStatus = invoice => {
    if(invoice.status.toLowerCase() === "paid") {
        return "paid"
    } else if(invoice.dueBy && isPastDue(formatAWSDate(invoice.dueBy))) {
        return "overdue"
    } else if (invoice.status.toLowerCase() === "partially_paid") {
        return "partially_paid"
    } else {
        return "active"
    }
}

export const resultsPerPageOptions = [
    {
        value: 10,
        label: '10'
    },
    {
        value: 25,
        label: '25'
    },
    {
        value: 50,
        label: '50'
    },
    {
        value: 100,
        label: '100'
    }
]

export const onPagination = (func, limit, filter, totalPages, totalResults, resultsArray, page, setPage) => (action) => {
    let newPage = 0
    // Calculate new page
    switch (action) {
        case 'FIRST':
            newPage = 1
            break;
        case 'BACK':
            newPage = page - 1
            break;
        case 'FORWARD':
            newPage = page + 1
            break;
        case 'LAST':
            newPage = totalPages
            break;
        default:
            newPage = page
    }
    setPage(newPage)
    //Perform the action based on the new page
    if(newPage === 1) {
        func(null, general.DESC, null, limit, filter, general.FORWARD, false)
    } else if (newPage === totalPages) {
        func(null, general.ASC, null, totalResults % limit, filter, general.FORWARD, true)
    } else if (action === 'FORWARD') {
        const offset = resultsArray.slice(-1)[0]
        func(null, general.DESC, offset, limit, filter, general.FORWARD, false)
    } else if (action === 'BACK') {
        const offset = resultsArray[0]
        func(null, general.DESC, offset, limit, filter, general.BACKWARDS, false)
    }
}

export const getTodayFormatted = () => {
    const today = new Date()
    const dd = String(today.getDate()).padStart(2, '0')
    const mm = String(today.getMonth() + 1).padStart(2, '0') //January is 0!
    const yyyy = today.getFullYear()
    return `${mm}/${dd}/${yyyy}`
}