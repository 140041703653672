/* global FreshworksWidget */
import React, {createContext, lazy, Suspense, useCallback, useEffect, useState} from 'react'

import {ModalSpinner, NotFound, openSpinner, usePortalNotification} from '@paytheory/pay-theory-ui'

import * as Login from './components/LoginComponents'

import './App.css'

import {Navigate, Route, Routes, useNavigate} from 'react-router-dom'

import {withAuthenticator} from "@aws-amplify/ui-react";

import * as network from './network'

import * as ROUTES from './constants/routes'

const Font = lazy(() =>
    import ('@paytheory/pay-theory-ui/dist/Font'))

// const Unauthorized = lazy(() =>
//   import ('@paytheory/books-ui/dist/common/Unauthorized'))

// const NotFound = lazy(() =>
//   import ('@paytheory/books-ui/dist/common/NotFound'))

const Transactions = lazy(() =>
    import ('./views/Transactions'))

const Settlements = lazy(() =>
    import ('./views/Settlements'))

const SettlementDetails = lazy(() =>
    import ('./views/SettlementDetails'))

const Settings = lazy(() =>
    import ('./views/Settings'))

const Chargebacks = lazy(() =>
    import ('./views/Chargebacks'))

const AllTrasactions = lazy(() =>
    import ('./views/AllTransactions'))

const Invoices = lazy(() =>
    import ('./views/Invoices'))

export const AppContext = createContext()

export const App = ({signOut, user}) => {
    const [accessToken, setAccessToken] = useState(false)
    const [idToken, setIdToken] = useState(false)
    const [connected, setConnected] = useState(false)
    const [websocket, setWebsocket] = useState(false)
    const [merchantUID, setMerchantUID] = useState('')
    const [merchant, setMerchant] = useState({})
    const [chargebackBadge, setChargebackBadge] = useState(null)
    const [defaultParams, setDefaultParams] = useState({})
    const typekit = process.env.REACT_APP_TYPEKIT

    const navigate = useNavigate()

    const {
        ErrorMessage,
        SuccessMessage
    } = usePortalNotification();

    useEffect(() => {
        if (user && !accessToken) {
            let userAccessToken = user.signInUserSession?.accessToken.jwtToken
            let userIdToken = user.signInUserSession?.idToken.jwtToken
            let merchant = user.signInUserSession?.idToken.payload['custom:merchant_uid']
            setIdToken(userIdToken)
            setAccessToken(userAccessToken)
            setMerchantUID(merchant)
        }
    }, [user, accessToken])

    useEffect(() => {
        if (accessToken && idToken && connected === false) {
            openSpinner()
            let socket = network.createSocket(accessToken, idToken, setConnected)
            setWebsocket(socket)
        }
    }, [accessToken, connected, idToken])

    const customSignOut = useCallback(() => {
        setAccessToken(false)
        setIdToken(false)
        setConnected(false)
        if (websocket) websocket.dispose()
        setWebsocket(false)
        signOut()
    }, [websocket])

    const getMerchantName = useCallback(() => {
        network.socketQuery(websocket, network.merchant.query(merchantUID))
            .then(response => {
                setMerchant(response.merchant)
                if (merchant.status === 'active') {
                    // navigate(ROUTES.PAYMENTS)
                } else {
                    // navigate(ROUTES.HOME)
                }
            })
            .catch(err => {
                console.log(err)
            })
    }, [websocket, merchantUID])

    const getPaymentParams = useCallback(() => {
        network.socketQuery(websocket, network.merchant.listPaymentParameters)
            .then(response => {
                const defaultPaymentParams = response.paymentParameters.find(param => param.PaymentParametersType === 'default')
                setDefaultParams(defaultPaymentParams)
            })
            .catch(err => {
                console.log(err)
            })
    }, [websocket, merchantUID])

    const getPendingDisputes = useCallback(() => {
        network.socketQuery(websocket, network.chargebacks.list)
            .then(response => {
                const chargebacks = response.disputes
                let requiredAction = chargebacks.items.filter(chargeback => chargeback.status === 'inquiry' || (chargeback.evidenceLastSendDate === 0 && chargeback.status === 'pending'))
                const totalActionRequired = requiredAction.length
                setChargebackBadge(totalActionRequired)
            })
            .catch(err => {
                console.log(err)
            })
    }, [websocket])

    const respondToSubscription = useCallback((response) => {
        if (response.asyncNotification?.asyncNotificationResponse) {
            let context = response.asyncNotification.asyncNotificationResponse.contextId
            if (context === 'DISPUTE_CHANGE') {
                getPendingDisputes()
            }
        }
    }, [getPendingDisputes])

    const subscribeToNotifications = useCallback(() => {
        network.socketSubsciption(websocket, respondToSubscription, network.subscribe.start(['dispute_change']))
            .then(response => {
                //Response is handled in the respondtoSubscription function
            })
            .catch(err => {
                console.log(err)
            })
    }, [respondToSubscription, websocket])


    useEffect(() => {
        if (websocket && merchantUID) {
            getMerchantName()
            subscribeToNotifications()
            getPendingDisputes()
            getPaymentParams()
        }
    }, [websocket, merchantUID, getMerchantName, subscribeToNotifications, getPendingDisputes])

    useEffect(() => {
        const minutesTillTimeout = 5
        const timeout = minutesTillTimeout * 60000
        const actions = ['mousemove', 'scroll', 'keydown', 'click', 'mousedown']

        let t = setTimeout(signOut, timeout)

        const updateTimeout = () => {
            clearTimeout(t)
            t = setTimeout(signOut, timeout)
        }

        actions.forEach((action) => {
            document.addEventListener(action, updateTimeout, {
                capture: false,
                passive: true
            })
        })

        return () => {
            actions.forEach((action) => {
                document.removeEventListener(action, updateTimeout)
            })
            clearTimeout(t)
        }
    }, [signOut])

    useEffect(() => {
        FreshworksWidget('hide', 'launcher');
    }, [])

    return (
        <div className="spinner-wrapper">
            <div className="modal-wrapper">
                <AppContext.Provider value={{
                    websocket,
                    connected,
                    signOut: customSignOut,
                    merchantUID,
                    merchant,
                    chargebackBadge,
                    ErrorMessage,
                    SuccessMessage,
                    defaultParams
                }}>
                    <Suspense fallback={<ModalSpinner on/>}>
                        <Routes>
                            <Route exact path="/" element={<Navigate replace to={ROUTES.HOME}/>}/>
                            <Route
                                exact
                                path={ROUTES.HOME}
                                element={<Transactions/>}

                            />
                            <Route
                                exact
                                path={ROUTES.ALL_TRANSACTIONS}
                                element={<AllTrasactions/>}
                            />
                            <Route
                                exact
                                path={ROUTES.INVOICES}
                                element={<Invoices />}
                            />
                            <Route
                                exact
                                path={ROUTES.SETTLEMENTS}
                                element={<Settlements/>}
                            />
                            <Route
                                exact
                                path={ROUTES.SETTLEMENTS_DETAILS}
                                element={<SettlementDetails/>}
                            />
                            <Route
                                exact
                                path={ROUTES.DISPUTES}
                                element={<Chargebacks/>}
                            />
                            <Route
                                exact
                                path={ROUTES.MERCHANT_SETTINGS}
                                element={<Settings/>}
                            />
                            <Route
                                path='*'
                                exact
                                element={<NotFound/>}
                            />
                        </Routes>
                        <Font typekit={typekit}/>
                    </Suspense>
                    <ModalSpinner/>
                </AppContext.Provider>
            </div>
        </div>
    )
}

export default withAuthenticator(App, {
    includeGreetings: false,
    loginMechanisms: ['email'],
    components: {
        Header: Login.Header,
        SignIn: {
            Header: Login.SignInHeader
        },
    }
});
